import { useState } from 'react'

import { TrackEventName, useAnalytics } from '../../../../analytics'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { hasKnowledgeBaseFeature } from '../../../../utils/feature-flags'
import { ViewportAnimator } from '../../../../utils/viewport-animator'
import { HtInfoPanel, HtSwitch, PanelType, Size } from '../../../base'
import { Popup } from '../../popup'
import { KnowledgeBaseAddonsPopup } from './knowledge-base-addons'

export const KnowledgeBasePopup = (): JSX.Element => {
  const analytics = useAnalytics()
  const { state, setKnowledgeBaseActive, setPopupContent } =
    useFlowBuilderSelector(ctx => ctx)

  const featureFlags = useFlowBuilderSelector(
    ctx => ctx.state.organizationContents.featureFlags
  )
  const [isKnowledgeBaseSwitched, setIsKnowledgeBaseSwitched] = useState(
    state.isKnowledgeBaseActive
  )

  const onSave = () => {
    setKnowledgeBaseActive(isKnowledgeBaseSwitched)
    if (isKnowledgeBaseSwitched) {
      ViewportAnimator.centerStartNode(state)
    }
    setPopupContent()
    analytics.trackEvent(TrackEventName.KNOWLEDGE_BASE_ACTIVATION, {
      action: isKnowledgeBaseSwitched ? 'activate' : 'deactivate',
      has_sources: state.organizationContents.knowledgeSources.length > 0,
    })
  }

  if (!hasKnowledgeBaseFeature(featureFlags)) {
    return <KnowledgeBaseAddonsPopup />
  }

  return (
    <Popup
      title={`${PopupType.KNOWLEDGE_BASE} settings`}
      confirmButton={{
        text: 'Save',
        disabled: state.isKnowledgeBaseActive === isKnowledgeBaseSwitched,
        onClick: onSave,
      }}
      message='Activate knowledge base to let AI give responses to customer queries based on the selected knowledge sources. Once activated, a new Knowledge base flow will be created in the flows dropdown, where its own conversational flow can be set up.'
    >
      <HtSwitch
        isSelected={isKnowledgeBaseSwitched}
        isReadOnly={state.isReadOnly}
        onChange={setIsKnowledgeBaseSwitched}
      >
        Knowledge base
      </HtSwitch>
      {state.organizationContents.knowledgeSources.length === 0 && (
        <HtInfoPanel
          type={PanelType.WARN}
          size={Size.MEDIUM}
          hasIcon={true}
          title='There are no knowledge sources uploaded yet.'
          text='To start using knowledge base responses, provide AI with knowledge by uploading sources to the knowledge sources section in the dashboard.'
        />
      )}
    </Popup>
  )
}
