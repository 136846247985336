import { ChangeEvent, memo, useEffect, useState } from 'react'

import { HelperText } from '../base-styles'
import { HtBadge, HtBadgeProps } from '../ht-badge/ht-badge'
import { HtIcon } from '../ht-icon/ht-icons'
import { Icon } from '../ht-icon/icons-list'
import {
  InputContainer,
  InputFooter,
  InputIcons,
  StyledHtInput,
} from '../ht-input/ht-input-styles'
import { TextSmallBold } from '../typography'
import { Size } from '../variants'
import { CharacterCounter } from './character-counter'
import { HtEmojiPicker } from './emoji-picker'

export interface HtInputProps {
  disabled?: boolean
  readOnly?: boolean
  error?: boolean
  helperText?: string
  inputRef?: React.RefObject<any>
  id?: string
  label?: string
  multiline?: boolean
  required?: boolean
  value: string
  actionButton?: JSX.Element
  placeholder?: string
  hasEmojiPicker?: boolean
  counterEnabled?: boolean
  counterMaxLength?: number
  defaultRows?: number
  size?: Size
  isLabelHidden?: boolean
  badge?: HtBadgeProps
  onChange?: (value: string) => void
  onKeyDown?: (evt: React.KeyboardEvent<any>) => void
  onCrossClick?: () => void
}

export const HtInput = memo(
  ({
    error,
    helperText,
    id,
    inputRef,
    label,
    multiline,
    actionButton,
    value,
    hasEmojiPicker,
    counterEnabled,
    counterMaxLength,
    defaultRows,
    placeholder,
    disabled,
    readOnly,
    size,
    isLabelHidden,
    badge,
    onChange,
    onKeyDown,
    onCrossClick,
  }: HtInputProps): JSX.Element => {
    const [isEmojiPickerOpen, toggleEmojiPicker] = useState(false)

    useEffect(() => resizeTextArea(), [value])

    const onInputChange = (
      evt: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
      onChange?.(evt.target.value)
    }

    const resizeTextArea = () => {
      if (!inputRef) return
      inputRef.current.style.height = 'inherit'
      inputRef.current.style.height = `${
        inputRef.current.scrollHeight as string
      }px`
    }

    const hasError = (): boolean => {
      const textExceedsMaxLength =
        counterMaxLength && value.length > counterMaxLength
      if (textExceedsMaxLength) return true
      return !!error
    }

    const props = {
      role: 'textbox',
      'aria-label': id || label,
      'aria-invalid': hasError(),
      ref: inputRef,
      placeholder: placeholder || label,
      value,
      disabled,
      onChange: onInputChange,
      onKeyDown,
    }

    const isEmojiPickerVisible = hasEmojiPicker && !disabled && !readOnly
    const isFooterVisible =
      hasEmojiPicker ||
      counterEnabled ||
      counterMaxLength ||
      actionButton ||
      helperText

    const isActionButtonVisible = actionButton && !readOnly

    return (
      <StyledHtInput $hasError={hasError()}>
        {(!isLabelHidden || badge) && (
          <div>
            {label && !isLabelHidden && <TextSmallBold>{label}</TextSmallBold>}
            {badge && <HtBadge {...badge} />}
          </div>
        )}
        <InputContainer $isReadOnly={readOnly} $size={size || Size.MEDIUM}>
          {multiline ? (
            <textarea {...props} rows={defaultRows || 1}></textarea>
          ) : (
            <input {...props} />
          )}
          {/* TODO. remove when we stop supporting old payloads */}
          {onCrossClick && (
            <InputIcons>
              <HtIcon icon={Icon.XMARK} onClick={() => onCrossClick()} />
            </InputIcons>
          )}
        </InputContainer>
        {isFooterVisible && (
          <InputFooter>
            <div>
              {isActionButtonVisible && actionButton}
              {helperText && <HelperText>{helperText}</HelperText>}
            </div>
            <div>
              <CharacterCounter
                enabled={counterEnabled}
                maxLength={counterMaxLength}
                textValue={props.value}
              />
              {isEmojiPickerVisible && (
                <HtIcon
                  icon={Icon.FACE_SMILE}
                  onClick={evt => {
                    inputRef?.current?.focus()
                    toggleEmojiPicker(!isEmojiPickerOpen)
                    evt.stopPropagation()
                  }}
                />
              )}
            </div>
          </InputFooter>
        )}
        {hasEmojiPicker && isEmojiPickerOpen && (
          <HtEmojiPicker
            inputRef={inputRef}
            value={value}
            closeEmojiPicker={() => toggleEmojiPicker(false)}
            onChange={onChange}
          />
        )}
      </StyledHtInput>
    )
  }
)
