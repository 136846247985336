import { useEffect, useRef } from 'react'

import { FeedbackType } from '../../domain/feedback'
import { Repository } from '../../repository/repository-utils'
import { SaveOrigin, TrackEventName, useAnalytics } from '../analytics'
import { useFlowBuilderSelector } from '../reducer/hooks'

// TODO: improve and move all save logic to this hook
export const useSave = (repository: Repository) => {
  const analytics = useAnalytics()
  const { state, toggleFlowSaved, updateAllNodes, setHash } =
    useFlowBuilderSelector(ctx => ctx)
  const stateRef = useRef(state)

  useEffect(() => {
    stateRef.current = state
  }, [state])

  const saveFlow = async (
    origin?: SaveOrigin,
    isRestoring?: boolean
  ): Promise<boolean> => {
    if (!state.currentLocale || (!isRestoring && state.isReadOnly)) {
      return true
    }

    if (origin) {
      analytics.trackEvent(TrackEventName.SAVE, { origin })
    }

    const writeResponse = await repository.cmsWriter.writeContent(
      stateRef.current
    )
    updateAllNodes(stateRef.current.nodes, false)

    if (writeResponse.feedbackStatus === FeedbackType.SUCCESS) {
      setHash(writeResponse.newHash)
      toggleFlowSaved(true)
      return true
    }

    return false
  }

  return { saveFlow }
}
