import { ReactFlowInstance } from 'reactflow'

import { BotConfig } from '../../domain/models/bot-config-models'
import { VersionHistoryData } from '../components/header/version-history-dropdown/version-history-dropdown'
import {
  ComputedPreviousFlow,
  LoadingMessage,
  NodeTypes,
  NonMessageContents,
  OrganizationContents,
  PopupContent,
} from '../types'
import { AiActionInterface, AiChangesHistory } from './actions/ai-actions'
import { EdgeActionInterface, EdgeChangesHistory } from './actions/edge-actions'
import { FeedbackActionInterface } from './actions/feedback-actions'
import { FlowActionInterface, FlowChangesHistory } from './actions/flow-actions'
import {
  LocaleActionInterface,
  LocaleChangesHistory,
} from './actions/locale-actions'
import { NodeActionInterface, NodeChangesHistory } from './actions/node-actions'
import {
  NonMessageActionInterface,
  NonMessageChangesHistory,
} from './actions/non-message-actions'
import {
  WebviewActionInterface,
  WebviewChangesHistory,
} from './actions/webview-actions'

export type ChangesHistory =
  | AiChangesHistory
  | EdgeChangesHistory
  | FlowChangesHistory
  | LocaleChangesHistory
  | NodeChangesHistory
  | NonMessageChangesHistory
  | WebviewChangesHistory

export type Action =
  | AiActionInterface
  | EdgeActionInterface
  | FlowActionInterface
  | LocaleActionInterface
  | NodeActionInterface
  | NonMessageActionInterface
  | WebviewActionInterface
  | FeedbackActionInterface
  | { type: ActionType.TOGGLE_FLOW_SAVED; isFlowSaved: boolean }
  | { type: ActionType.SET_AUTH_TOKEN; authToken: string }
  | { type: ActionType.TOGGLE_LOCALES_PANEL; toggle: boolean }
  | { type: ActionType.CLOSE_NODE_EDITOR }
  | { type: ActionType.TOGGLE_INTERACTIVITY; isInteractive: boolean }
  | {
      type: ActionType.SET_POPUP_CONTENT
      content?: PopupContent
    }
  | {
      type: ActionType.SET_REACT_FLOW_REFS
      reactFlowInstance: ReactFlowInstance
      reactFlowWrapper: React.RefObject<HTMLDivElement>
    }
  | {
      type: ActionType.SET_COMPUTED_PREVIOUS_FLOWS
      computedPreviousFlows: ComputedPreviousFlow[]
    }
  | {
      type: ActionType.SET_ORGANIZATION_CONTENTS
      organizationContents: OrganizationContents
    }
  | {
      type: ActionType.UPDATE_ALL_CONTENTS
      nodes?: NodeTypes[]
      nonMessageContents?: NonMessageContents
    }
  | { type: ActionType.UNDO }
  | { type: ActionType.REDO }
  | { type: ActionType.RESTORE_CHANGE_HISTORY }
  | { type: ActionType.SET_HASH_PUBLISHED; hashPublished: string }
  | { type: ActionType.SET_HASH; hash: string }
  | { type: ActionType.SET_BOT_CONFIG; botConfig: BotConfig }
  | { type: ActionType.SET_LOADING_MESSAGE; message?: LoadingMessage }
  | {
      type: ActionType.SET_CURRENT_VERSION
      currentVersion?: VersionHistoryData
    }
  | { type: ActionType.TOGGLE_IS_OFFLINE; isOffline: boolean }

export enum ActionType {
  ADD_FEEDBACK_MESSAGE = 'ADD_FEEDBACK_MESSAGE',
  ADD_FLOW = 'ADD_FLOW',
  ADD_LOCALES = 'ADD_LOCALES',
  ADD_PAYLOAD = 'ADD_PAYLOAD',
  ADD_URL = 'ADD_URL',
  ADD_WEBVIEW = 'ADD_WEBVIEW',
  CLOSE_NODE_EDITOR = 'CLOSE_NODE_EDITOR',
  CONNECT_NODES = 'CONNECT_NODES',
  COPY_ELEMENTS = 'COPY_ELEMENTS',
  CUT_ELEMENTS = 'CUT_ELEMENTS',
  EDIT_URL = 'EDIT_URL',
  NODE_DRAG_START = 'NODE_DRAG_START',
  NODE_DRAG_STOP = 'NODE_DRAG_STOP',
  NODE_DROP = 'NODE_DROP',
  PASTE_ELEMENTS = 'PASTE_ELEMENTS',
  REDO = 'REDO',
  REMOVE_EDGES_BY_ID = 'REMOVE_EDGES_BY_ID',
  REMOVE_FEEDBACK_MESSAGES = 'REMOVE_FEEDBACK_MESSAGES',
  REMOVE_FLOW = 'REMOVE_FLOW',
  REMOVE_LOCALES = 'REMOVE_LOCALES',
  REMOVE_NODES = 'REMOVE_NODES',
  REMOVE_PAYLOAD = 'REMOVE_PAYLOAD',
  REMOVE_URL = 'REMOVE_URL',
  REMOVE_WEBVIEW = 'REMOVE_WEBVIEW',
  RESTORE_CHANGE_HISTORY = 'RESTORE_CHANGE_HISTORY',
  SELECT_AI_MODEL = 'SELECT_AI_MODEL',
  SELECT_EDGES = 'SELECT_EDGES',
  SELECT_FLOW = 'SELECT_FLOW',
  SELECT_LOCALE = 'SELECT_LOCALE',
  SELECT_NODE = 'SELECT_NODE',
  SELECT_WEBVIEW = 'SELECT_WEBVIEW',
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
  SET_BOT_CONFIG = 'SET_BOT_CONFIG',
  SET_BOT_VARIABLES = 'SET_BOT_VARIABLES',
  SET_COMPUTED_PREVIOUS_FLOWS = 'SET_COMPUTED_PREVIOUS_FLOWS',
  SET_CURRENT_VERSION = 'SET_CURRENT_VERSION',
  SET_FLOWS = 'SET_FLOWS',
  SET_HASH = 'SET_HASH',
  SET_HASH_PUBLISHED = 'SET_HASH_PUBLISHED',
  SET_KNOWLEDGE_BASE_ACTIVE = 'SET_KNOWLEDGE_BASE_ACTIVE',
  SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE',
  SET_LOCALES = 'SET_LOCALES',
  SET_ORGANIZATION_CONTENTS = 'SET_ORGANIZATION_CONTENTS',
  SET_POPUP_CONTENT = 'SET_POPUP_CONTENT',
  SET_REACT_FLOW_REFS = 'SET_REACT_FLOW_REFS',
  SET_SELECTED_NODES = 'SET_SELECTED_NODES',
  SET_WEBVIEWS = 'SET_WEBVIEWS',
  TOGGLE_FLOW_SAVED = 'TOGGLE_FLOW_SAVED',
  TOGGLE_INTERACTIVITY = 'TOGGLE_INTERACTIVITY',
  TOGGLE_IS_OFFLINE = 'TOGGLE_IS_OFFLINE',
  TOGGLE_LOCALES_PANEL = 'TOGGLE_LOCALES_PANEL',
  UNDO = 'UNDO',
  UPDATE_ALL_CONTENTS = 'UPDATE_ALL_CONTENTS',
  UPDATE_ALL_NODES = 'UPDATE_ALL_NODES',
  UPDATE_NODE = 'UPDATE_NODE',
}
