import {
  OrganizationAiIntent,
  OrganizationAiModel,
} from '../../domain/models/organization-models'
import { HubtypeService } from '../../repository/hubtype/hubtype-service'
import { FlowContent, OrganizationContents } from '../types'

export const getFlowAiModel = (
  flowContent: FlowContent,
  organizationContents: OrganizationContents
): OrganizationAiModel | undefined => {
  return organizationContents.aiModels.find(
    aiModel => aiModel.id === flowContent.aiModelId
  )
}

export const getAiModelIntents = async (
  aiModel: OrganizationAiModel,
  authToken: string
): Promise<OrganizationAiIntent[]> => {
  const orgAiIntents = await HubtypeService.getOrganizationAiIntents(
    authToken,
    aiModel.id
  )
  return orgAiIntents || []
}
