/* eslint-disable @typescript-eslint/no-empty-function */
import cloneDeep from 'lodash.clonedeep'
import { Edge, XYPosition } from 'reactflow'
import { v7 as uuidv7 } from 'uuid'

import { MAIN_FLOW } from '../../../UI/constants'
import { NonMessageContents } from '../../../UI/types'
import { Feedback } from '../../feedback'
import { HtBaseNode } from '../cms/hubtype/common'
import { LocaleCode } from '../locales/code'
import { ContentId } from './common'
import { ContentType } from './content-types'

export abstract class ContentFieldsBase {
  // react-flow library requires ids for all nodes. Before a new content is added to CMS, we create a temporary id
  protected constructor(public id = uuidv7()) {}
  abstract contentType(): ContentType

  getContentId(): ContentId {
    return { id: this.id, type: this.contentType() }
  }

  fieldsIncludeString = (
    fields: (string | undefined)[],
    value: string
  ): boolean => {
    const includesString = fields.map(f => {
      if (f && typeof f === 'string' && f.toLowerCase().includes(value)) {
        return true
      }
      return false
    })
    return includesString.indexOf(true) !== -1
  }
}

export abstract class TopContentFieldsBase extends ContentFieldsBase {
  public edges: Edge[] = []
  public errors: {
    showErrors?: boolean
    hasDuplicatedCode?: boolean
    fieldErrors: string[]
    localesWithErrors: string[]
  }
  constructor(
    public code = '',
    public flowId = MAIN_FLOW.id,
    public isCodeAiGenerated = false,
    public isMeaningful = false
  ) {
    super()
    this.errors = { fieldErrors: [], localesWithErrors: [] }
  }
  abstract stringContentType(): string
  abstract hasString(value: string): boolean
  abstract getConnections(edges: Edge[]): any

  setValuesByLocale(
    locale: string,
    nonMessageContents: NonMessageContents | undefined
  ): void {}
  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {}
  removeLocales(localesToRemove: LocaleCode[]): void {}
  setContentByLocale(locale: LocaleCode): void {}

  // TODO: remove this method
  reportMissingFields(fields: string[], feedback: Feedback): void {
    fields.forEach(field => {
      const message = `${this.getInitialErrorMessage(field)} is required.`
      this.reportError(message, this.id, feedback)
    })
  }

  getInitialErrorMessage(field: string): string {
    const type = this.stringContentType()
    if (this.code === '') {
      return `Error in ${type}: field ${field}`
    }
    return `Error in ${type} with id "${this.code}": field ${field}`
  }

  getMissingFieldsErrors(fields: string[]): string[] {
    return fields.map(
      field => `${this.getInitialErrorMessage(field)} is required.`
    )
  }

  getTooLongFieldsErrors(fields: string[]): string[] {
    return fields.map(
      field => `${this.getInitialErrorMessage(field)} is too long.`
    )
  }

  reportTooLongFields(fields: string[], feedback: Feedback): void {
    fields.forEach(field => {
      const message = `${this.getInitialErrorMessage(field)} is too long.`
      this.reportError(message, this.id, feedback)
    })
  }

  reportError(message: string, nodeId: string, feedback: Feedback): void {
    feedback.reportError(message, { nodeId })
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors = []
    return []
  }

  getFollowUp = (edges: any[]): any | undefined => {
    return edges.find(e => e.sourceHandle === 'follow_up_' + this.id)
  }

  static setHubtypeCmsCommonData(
    newNode: TopContentFieldsBase,
    htNode: HtBaseNode
  ) {
    newNode.id = htNode.id
    newNode.code = htNode.code
    newNode.isCodeAiGenerated = htNode.is_code_ai_generated || false
    newNode.flowId = htNode.flow_id || MAIN_FLOW.id
    newNode.isMeaningful = htNode.is_meaningful || false
    newNode.errors.showErrors = true
  }

  getHubtypeCmsCommonData(position: XYPosition): HtBaseNode {
    return {
      id: this.id,
      code: this.code,
      is_code_ai_generated: this.isCodeAiGenerated,
      is_meaningful: this.isMeaningful,
      flow_id: this.flowId,
      meta: position,
      follow_up: undefined,
      target: undefined,
    }
  }

  static copyContent<T extends TopContentFieldsBase>(
    content: T,
    newContent: T
  ): T {
    const clonedContent = cloneDeep(content)
    clonedContent.id = newContent.id
    Object.assign(newContent, clonedContent)
    return newContent
  }

  getConditionalIdMappings = <T extends { id: string }>(
    newItems: T[],
    oldItems: T[],
    oldId: string
  ) => {
    const itemsMappings = oldItems.reduce(
      (acc, oldItem, index) => {
        if (newItems[index]) {
          const key = `${oldId}-${oldItem.id}`
          const value = `${this.id}-${newItems[index].id}`
          acc[key] = value
        }
        return acc
      },
      {} as { [key: string]: string }
    )
    const defaultKey = `${oldId}-default`
    const defaultValue = `${this.id}-default`
    const defaultMapping = { [defaultKey]: defaultValue }
    return Object.assign({}, itemsMappings, defaultMapping)
  }

  getConditionalConnections = <T extends { id: string }>(
    items: T[],
    edges: Edge[]
  ) => {
    const itemsEdges = items.map(item =>
      edges.find(edge => edge.sourceHandle === this.id + '-' + item.id)
    )
    const defaultEdge = edges.find(
      edge => edge.sourceHandle === this.id + '-default'
    )
    return {
      buttons: [...itemsEdges, defaultEdge],
      followUp: undefined,
    }
  }
}
