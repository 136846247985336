import { memo } from 'react'

import { OrganizationAiModel } from '../../../domain/models/organization-models'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { PopupContent, PopupType } from '../../types'
import { HtModal, ModalProps } from '../base'
import { IntentModelsPopup } from './popups/ai/intent-models'
import { KnowledgeBasePopup } from './popups/ai/knowledge-base'
import { DeleteLocalesPopup } from './popups/delete-locale'
import { DeletePayloadPopup } from './popups/delete-payload'
import { DeleteUrlPopup } from './popups/delete-url'
import { EditUrlPopup } from './popups/edit-url'
import { AddFlowPopup } from './popups/flows/add-flow'
import { DeleteFlowPopup } from './popups/flows/delete-flow'
import { EditFlowPopup } from './popups/flows/rename-flow'
import { PublishPopup } from './popups/publish'
import { RestoreVersionPopup } from './popups/restore-version'
import { SessionConflictPopup } from './popups/session/session-conflict'
import { SessionEndedPopup } from './popups/session/session-ended'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export type PopupProps = Optional<ModalProps, 'cancel'>

export const Popup = memo((props: PopupProps): JSX.Element => {
  const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)

  const onCancel = (): void => {
    props.cancel?.()
    setPopupContent()
  }

  return <HtModal {...props} cancel={onCancel} />
})

export const renderPopup = (
  content: PopupContent,
  onPublish: () => Promise<void>,
  loadAiModel: (aiModel: OrganizationAiModel) => Promise<void>,
  restoreVersion: () => Promise<void>
): JSX.Element => {
  switch (content.type) {
    case PopupType.INTENT_MODELS:
      return <IntentModelsPopup loadAiModel={loadAiModel} />
    case PopupType.KNOWLEDGE_BASE:
      return <KnowledgeBasePopup />
    case PopupType.PUBLISH:
      return <PublishPopup onPublish={onPublish} />
    case PopupType.REMOVE_LOCALES:
      return <DeleteLocalesPopup {...content} />
    case PopupType.RESTORE_VERSION:
      return (
        <RestoreVersionPopup {...content} restoreVersion={restoreVersion} />
      )
    case PopupType.ADD_FLOW:
      return <AddFlowPopup />
    case PopupType.EDIT_FLOW:
      return <EditFlowPopup {...content} />
    case PopupType.DELETE_FLOW:
      return <DeleteFlowPopup {...content} />
    case PopupType.DELETE_PAYLOAD:
      return <DeletePayloadPopup {...content} />
    case PopupType.DELETE_URL:
      return <DeleteUrlPopup {...content} />
    case PopupType.EDIT_URL:
      return <EditUrlPopup {...content} />
    case PopupType.SESSION_CONFLICT:
      return <SessionConflictPopup {...content} />
    case PopupType.SESSION_ENDED:
      return <SessionEndedPopup {...content} />
    default:
      throw new Error(`Not implemented popupType`)
  }
}
