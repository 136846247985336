import { Feedback } from '../../domain/feedback'
import { HtBotActionNode } from '../../domain/models/cms/hubtype/bot-action'
import { HtCarouselNode } from '../../domain/models/cms/hubtype/carousel'
import { HtButton } from '../../domain/models/cms/hubtype/common'
import { HtFallbackNode } from '../../domain/models/cms/hubtype/fallback'
import { HtFunctionNode } from '../../domain/models/cms/hubtype/function'
import { HtGoToFlowNode } from '../../domain/models/cms/hubtype/go-to-flow'
import { HtHandoffNode } from '../../domain/models/cms/hubtype/handoff'
import { HtImageNode } from '../../domain/models/cms/hubtype/image'
import { HtIntentNode } from '../../domain/models/cms/hubtype/intent'
import { HtKeywordNode } from '../../domain/models/cms/hubtype/keyword'
import {
  HtNodeComponent,
  HtNodeWithContent,
  HtNodeWithoutContent,
} from '../../domain/models/cms/hubtype/nodes'
import { HtTextNode } from '../../domain/models/cms/hubtype/text'
import { HtVideoNode } from '../../domain/models/cms/hubtype/video'
import {
  HtWhatsappButtonListNode,
  HtWhatsappButtonListRow,
} from '../../domain/models/cms/hubtype/whatsapp'
import {
  CarouselFields,
  ContentType,
  PayloadFields,
  TextFields,
  UrlFields,
  WhatsappButtonListFields,
} from '../../domain/models/content-fields'
import {
  CustomConditionNodeInterface,
  VariableFormat,
} from '../../nodes/custom-conditional'
import { KnowledgeBaseNodeInterface } from '../../nodes/knowledge-base'
import { HtKnowledgeBaseNode } from '../../nodes/knowledge-base/ht-model'
import { SmartIntentNodeInterface } from '../../nodes/smart-intent'
import { HtSmartIntentNode } from '../../nodes/smart-intent/ht-model'
import { WhatsappCTAUrlButtonNodeInterface } from '../../nodes/whatsapp-cta-url-button'
import { HtWhatsappCTAUrlButtonNode } from '../../nodes/whatsapp-cta-url-button/ht-model'
import {
  QUEUE_CLOSED,
  QUEUE_OPEN,
  QUEUE_OPEN_WITHOUT_AGENTS,
} from '../../UI/constants'
import {
  BotActionNode,
  CarouselNode,
  ChannelNode,
  CountryConditionNode,
  FallbackNode,
  GoToFlowNode,
  HandoffNode,
  ImageNode,
  IntentNode,
  KeywordNode,
  NodeTypes,
  NonMessageContents,
  QueueStatusNode,
  State,
  TextNode,
  VideoNode,
  WhatsappButtonListNode,
} from '../../UI/types'
import { HtWebviewComponent } from '../../webviews/ht-model'
import { isWebview } from '../../webviews/utils'
import { LinkFactory } from './hubtype-links-factory'

export class NodeFactory {
  private edgeFactory
  constructor(private feedback: Feedback) {
    this.edgeFactory = new LinkFactory()
  }

  public getHubtypeNodes(state: State): {
    hubtypeNodes: HtNodeComponent[]
    webviewContent: HtWebviewComponent[]
  } {
    let hubtypeNodes: HtNodeComponent[] = []
    const nonMessageNodes = this.getNonMessageNodes(state.nonMessageContents)
    const messageNodes = state.nodes
      .filter(
        node =>
          node.data.contentType() !== ContentType.START &&
          !isWebview(node.data.flowId)
      )
      .map(node => this.getMessageNodes(node, state))
    const webviewContent = this.getWebviewContent(state)
    hubtypeNodes = [...nonMessageNodes, ...messageNodes]
    return {
      hubtypeNodes,
      webviewContent,
    }
  }

  private getWebviewContent(state: State): HtWebviewComponent[] {
    //@ts-ignore
    return state.nodes
      .filter(
        node =>
          (node.data.contentType() === ContentType.TEXT ||
            node.data.contentType() === ContentType.IMAGE) &&
          isWebview(node.data.flowId)
      )
      .map(node => this.getMessageNodes(node, state)) as HtWebviewComponent[]
  }

  private getNonMessageNodes(
    nonMessageContents: NonMessageContents
  ): HtNodeWithoutContent[] {
    const urls = this.convertNonMessageContents(nonMessageContents.urls)
    const payloads = this.convertNonMessageContents(nonMessageContents.payloads)
    const noneMessageNodes = [...urls, ...payloads]
    return noneMessageNodes
  }

  private getMessageNodes(node: NodeTypes, state: State): HtNodeWithContent {
    switch (node.type) {
      case ContentType.TEXT:
        return this.getText(node, state)
      case ContentType.IMAGE:
        return this.getImage(node, state)
      case ContentType.CAROUSEL:
        return this.getCarousel(node, state)
      case ContentType.QUEUE_STATUS:
        return this.getQueueStatus(node, state)
      case ContentType.CHANNEL:
        return this.getChannel(node, state)
      case ContentType.COUNTRY_CONDITION:
        return this.getCountry(node, state)
      case ContentType.CUSTOM_CONDITION:
        return this.getCustomCondition(node, state)
      case ContentType.FALLBACK:
        return this.getFallback(node, state)
      case ContentType.WHATSAPP_BUTTON_LIST:
        return this.getWhatsappButtonList(node, state)
      case ContentType.HANDOFF:
        return this.getHandoff(node, state)
      case ContentType.VIDEO:
        return this.getVideo(node, state)
      case ContentType.KEYWORD:
        return this.getKeywords(node, state)
      case ContentType.INTENT:
        return this.getIntent(node, state)
      case ContentType.SMART_INTENT:
        return this.getSmartIntent(node, state)
      case ContentType.GO_TO_FLOW:
        return this.getGoToFlow(node, state)
      case ContentType.BOT_ACTION:
        return this.getBotAction(node, state)
      case ContentType.WHATSAPP_CTA_URL_BUTTON:
        return this.getWhatsappCTAUrlButton(node, state)
      case ContentType.KNOWLEDGE_BASE:
        return this.getKnowledgeBase(node, state)
      default:
        throw new Error(`This content is not supported`)
    }
  }

  private getNode(node: NodeTypes, state: State): HtNodeWithContent {
    const locale = state.currentLocale.code
    const hubtypeNode = node.data.toHubtypeCMS(node.position, locale)
    this.edgeFactory.addNodeLink(node.data.edges, hubtypeNode)
    return hubtypeNode
  }

  private getText(node: TextNode, state: State): HtTextNode {
    const hubtypeNode = this.getNode(node, state) as HtTextNode
    hubtypeNode.content.buttons = hubtypeNode.content.buttons?.map(
      (button, index) => {
        this.edgeFactory.addFieldLink(node, button)
        this.reportButtonErrors(node.data, index, button)
        return button
      }
    )
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private getImage(node: ImageNode, state: State): HtImageNode {
    const hubtypeNode = this.getNode(node, state) as HtImageNode
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private getCarousel(node: CarouselNode, state: State): HtCarouselNode {
    const hubtypeNode = this.getNode(node, state) as HtCarouselNode
    hubtypeNode.content.elements.forEach((element, index) => {
      this.edgeFactory.addFieldLink(node, element.button)
      this.reportElementErrors(node.data, index, element.button)
    })
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private getQueueStatus(node: QueueStatusNode, state: State): HtFunctionNode {
    const hubtypeNode = this.getNode(node, state) as HtFunctionNode
    this.edgeFactory.addConditionLink(node.data.edges, hubtypeNode, QUEUE_OPEN)
    this.edgeFactory.addConditionLink(
      node.data.edges,
      hubtypeNode,
      QUEUE_CLOSED
    )
    this.edgeFactory.addConditionLink(
      node.data.edges,
      hubtypeNode,
      QUEUE_OPEN_WITHOUT_AGENTS
    )
    node.data.reportErrors(this.feedback, hubtypeNode.content.result_mapping)
    return hubtypeNode
  }

  private getChannel(node: ChannelNode, state: State): HtFunctionNode {
    const hubtypeNode = this.getNode(node, state) as HtFunctionNode
    this.edgeFactory.addConditionLink(node.data.edges, hubtypeNode, 'default')
    node.data.channels.forEach(channel => {
      this.edgeFactory.addConditionLink(
        node.data.edges,
        hubtypeNode,
        channel.id
      )
    })
    node.data.reportErrors(
      this.feedback,
      hubtypeNode.content.result_mapping[0].target
    )
    return hubtypeNode
  }

  private getCountry(node: CountryConditionNode, state: State): HtFunctionNode {
    const hubtypeNode = this.getNode(node, state) as HtFunctionNode
    this.edgeFactory.addConditionLink(node.data.edges, hubtypeNode, 'default')
    node.data.countries.forEach(country => {
      this.edgeFactory.addConditionLink(
        node.data.edges,
        hubtypeNode,
        country.id
      )
    })
    node.data.reportErrors(
      this.feedback,
      hubtypeNode.content.result_mapping[0].target
    )
    return hubtypeNode
  }

  private getCustomCondition(
    node: CustomConditionNodeInterface,
    state: State
  ): HtFunctionNode {
    const hubtypeNode = this.getNode(node, state) as HtFunctionNode
    if (node.data.variableFormat !== VariableFormat.BOOLEAN) {
      this.edgeFactory.addConditionLink(node.data.edges, hubtypeNode, 'default')
    }
    node.data.values.forEach(value => {
      this.edgeFactory.addConditionLink(
        node.data.edges,
        hubtypeNode,
        value.id,
        value.getFormattedValue(node.data.variableFormat)
      )
    })
    if (node.data.variableFormat === VariableFormat.BOOLEAN) {
      const resultMapping = {
        result: 'default',
        target: hubtypeNode.content.result_mapping[1].target,
      }
      hubtypeNode.content.result_mapping.unshift(resultMapping)
    }
    node.data.reportErrors(
      this.feedback,
      hubtypeNode.content.result_mapping[0].target
    )

    return hubtypeNode
  }

  private getFallback(node: FallbackNode, state: State): HtFallbackNode {
    const hubtypeNode = node.data.toHubtypeCMS(node.position)
    this.edgeFactory.addFallbackLinks(node.data.edges, hubtypeNode)
    node.data.reportErrors(this.feedback, hubtypeNode.content.first_message)
    return hubtypeNode
  }

  private getIntent(node: IntentNode, state: State): HtIntentNode {
    const hubtypeNode = this.getNode(node, state) as HtIntentNode
    node.data.reportErrors(this.feedback, hubtypeNode.target)
    return hubtypeNode
  }

  private getSmartIntent(
    node: SmartIntentNodeInterface,
    state: State
  ): HtSmartIntentNode {
    const hubtypeNode = this.getNode(node, state) as HtSmartIntentNode
    node.data.reportErrors(this.feedback, hubtypeNode.target)
    return hubtypeNode
  }

  private getKeywords(node: KeywordNode, state: State): HtKeywordNode {
    const hubtypeNode = this.getNode(node, state) as HtKeywordNode
    node.data.reportErrors(this.feedback, hubtypeNode.target)
    return hubtypeNode
  }

  private getVideo(node: VideoNode, state: State): HtVideoNode {
    const hubtypeNode = this.getNode(node, state) as HtVideoNode
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private getHandoff(node: HandoffNode, state: State): HtHandoffNode {
    const hubtypeNode = this.getNode(node, state) as HtHandoffNode
    node.data.reportErrors(this.feedback, hubtypeNode.target)
    return hubtypeNode
  }

  private getGoToFlow(node: GoToFlowNode, state: State): HtGoToFlowNode {
    const hubtypeNode = this.getNode(node, state) as HtGoToFlowNode
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private getBotAction(node: BotActionNode, state: State): HtBotActionNode {
    const hubtypeNode = this.getNode(node, state) as HtBotActionNode
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private getWhatsappButtonList(
    node: WhatsappButtonListNode,
    state: State
  ): HtWhatsappButtonListNode {
    const hubtypeNode = this.getNode(node, state) as HtWhatsappButtonListNode
    hubtypeNode.content.sections.forEach((section, sectionIndex) => {
      this.reportSectionErrors(node.data, sectionIndex)
      section.rows.forEach((row, rowIndex) => {
        this.edgeFactory.addFieldLink(node, row)
        this.reportRowErrors(node.data, sectionIndex, rowIndex, row)
      })
    })
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private getWhatsappCTAUrlButton(
    node: WhatsappCTAUrlButtonNodeInterface,
    state: State
  ): HtWhatsappCTAUrlButtonNode {
    const hubtypeNode = this.getNode(node, state) as HtWhatsappCTAUrlButtonNode
    this.edgeFactory.addFieldLink(node, hubtypeNode.content.button)
    node.data.button.reportErrors(
      node.data,
      this.feedback,
      hubtypeNode.content.button.target
    )
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private getKnowledgeBase(
    node: KnowledgeBaseNodeInterface,
    state: State
  ): HtKnowledgeBaseNode {
    const hubtypeNode = this.getNode(node, state) as HtKnowledgeBaseNode
    node.data.reportErrors(this.feedback)
    return hubtypeNode
  }

  private convertNonMessageContents(
    nonMessageContent: UrlFields[] | PayloadFields[]
  ): HtNodeWithoutContent[] {
    return nonMessageContent.map(content => content.toHubtypeCMS())
  }

  private reportElementErrors(
    carousel: CarouselFields,
    elementIndex: number,
    button: HtButton
  ) {
    const element = carousel.elements[elementIndex]
    element.reportErrors(carousel, this.feedback)
    element.buttons[0].reportErrors(carousel, this.feedback, button.target)
  }

  private reportButtonErrors(
    text: TextFields,
    buttonIndex: number,
    button: HtButton
  ) {
    text.buttons[buttonIndex].reportErrors(text, this.feedback, button.target)
  }

  private reportSectionErrors(
    whatsappButtonList: WhatsappButtonListFields,
    sectionIndex: number
  ) {
    whatsappButtonList.sections[sectionIndex].reportErrors(
      whatsappButtonList,
      this.feedback
    )
  }

  private reportRowErrors(
    whatsappButtonList: WhatsappButtonListFields,
    sectionIndex: number,
    rowIndex: number,
    row: HtWhatsappButtonListRow
  ) {
    whatsappButtonList.sections[sectionIndex].rows[rowIndex].reportErrors(
      whatsappButtonList,
      this.feedback,
      row.target
    )
  }
}
