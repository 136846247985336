import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { NonMessageContents } from '../../../UI/types'
import { Feedback } from '../../feedback'
import { HtNodeLink } from '../cms/hubtype/common'
import { HtHandoffNode } from '../cms/hubtype/handoff'
import { HtContentType } from '../cms/hubtype/node-types'
import {
  ContentType,
  MessageContentType,
  PayloadFields,
  QueueFields,
  TopContentFieldsBase,
} from '../content-fields'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import { PayloadLocale, QueueLocale } from './common'

export class HandoffFields extends TopContentFieldsBase {
  public queue?: QueueFields
  public payload?: PayloadFields
  constructor(
    public queueLocales: QueueLocale[] = [],
    public payloadLocales: PayloadLocale[] = [],
    public hasAutoAssign = false
  ) {
    super()
  }

  contentType(): MessageContentType {
    return ContentType.HANDOFF
  }

  stringContentType(): string {
    return 'handoff'
  }

  setValuesByLocale(
    locale: string,
    nonMessageContents: NonMessageContents
  ): void {
    this.queue = LocaleManager.getQueueByLocale(this.queueLocales, locale)
    this.payloadLocales = this.payloadLocales.reduce(
      (acc: PayloadLocale[], payloadLocale) => {
        if (
          nonMessageContents.payloads.some(
            field => field.id === payloadLocale?.id
          )
        ) {
          acc.push(payloadLocale)
        }
        return acc
      },
      []
    )
    this.payload = nonMessageContents.payloads.find(
      field => field.id === this.payloadLocales[0]?.id
    )
  }

  setContentByLocale(locale: LocaleCode): void {
    this.queueLocales = LocaleManager.setQueueByLocale(
      this.queueLocales,
      this.queue,
      locale
    )
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.payloadLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.queueLocales, newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.payloadLocales, localesToRemove)
    LocaleManager.removeLocales(this.queueLocales, localesToRemove)
  }

  static fromHubtypeCMS(
    component: HtHandoffNode,
    locale: string,
    nonMessageContents: NonMessageContents
  ): HandoffFields {
    const newHandoff = new HandoffFields()
    this.setHubtypeCmsCommonData(newHandoff, component)
    newHandoff.hasAutoAssign = component.content.has_auto_assign || false

    newHandoff.queueLocales = component.content.queue.map(
      queueLocale => queueLocale as QueueLocale
    )
    newHandoff.payloadLocales = component.content.payload.map(
      payloadLocale => payloadLocale as PayloadLocale
    )
    newHandoff.setValuesByLocale(locale, nonMessageContents)
    return newHandoff
  }

  toHubtypeCMS(position: XYPosition, locale: LocaleCode): HtHandoffNode {
    this.setContentByLocale(locale)
    const node: HtHandoffNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.HANDOFF,
      content: {
        queue: this.queueLocales,
        payload: this.payloadLocales,
        has_auto_assign: this.hasAutoAssign,
      },
    }
    return node
  }

  reportErrors(feedback: Feedback, onFinish?: HtNodeLink): boolean {
    const errors = []
    if (!this.code) errors.push('content ID')
    if (!this.queue) errors.push('queue')
    if (errors.length === 0) return false
    this.reportMissingFields(errors, feedback)
    return true
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('content ID')
    if (!this.queue) requiredFields.push('queue')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors = LocaleManager.getLocalesWithEmptyQueue(
      this.queueLocales,
      locales
    )
    return this.errors.localesWithErrors
  }

  reportNonExistingQueueError(feedback: Feedback): void {
    if (this.queue?.name) {
      this.reportError(
        `Queue with name "${this.queue.name}" not longer exists. Select a different queue`,
        this.id,
        feedback
      )
    }
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.code, this.queue?.name], value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(content: HandoffFields): HandoffFields {
    const newHandoff = new HandoffFields()
    TopContentFieldsBase.copyContent(content, newHandoff)
    if (content.queue) {
      newHandoff.queue = QueueFields.getCopy(content.queue)
    }
    return newHandoff
  }
}
