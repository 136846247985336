import { useEffect, useState } from 'react'
import { ReactFlowProvider } from 'reactflow'

import { FeedbackType } from '../domain/feedback'
import { ContentType } from '../domain/models/content-fields'
import { Locale } from '../domain/models/locales/locale'
import {
  HubtypeUser,
  OrganizationAiModel,
} from '../domain/models/organization-models'
import { KnowledgeBaseFields } from '../nodes/knowledge-base'
import { FlowBuilderService } from '../repository/hubtype/flow-builder-service'
import { HubtypeService } from '../repository/hubtype/hubtype-service'
import { Repository } from '../repository/repository-utils'
import { SaveOrigin, TrackEventName, useAnalytics } from './analytics'
import { AiGeneratedContentId } from './components/feedback-panel/ai-notifications-panel'
import { FeedbackPanel } from './components/feedback-panel/feedback-panel'
import { Header } from './components/header/header'
import { VersionHistoryData } from './components/header/version-history-dropdown/version-history-dropdown'
import { LocalesPanel } from './components/locale-panel/locales-panel'
import { NodeEditorPanel } from './components/node-editor-panel/node-editor-panel'
import { renderPopup } from './components/popup/popup'
import { VersionBanner } from './components/version-banner/version-banner'
import {
  DRAFT_VERSION,
  LATEST_VERSION,
  SESSION_RESOLUTION_TIME,
} from './constants'
import { useFeedbackMessage } from './custom-hooks/use-feedback-message'
import { SessionResolution, usePusher } from './custom-hooks/use-pusher'
import { useSave } from './custom-hooks/use-save'
import Flow from './flow'
import { FlowContainer } from './flow-styles'
import { postCloseFromFlowBuilderMessage } from './hubtype-events'
import { useFlowBuilderSelector } from './reducer/hooks'
import { setFBAppStoragePreviewState } from './test-webchat-app/utils'
import {
  FlowContent,
  LoadingMessage,
  OrganizationContents,
  PopupType,
  TextNode,
  WhatsappButtonListNode,
} from './types'
import { useFlowBuilderFeedback } from './use-flow-builder-feedback'
import { getAiModelIntents, getFlowAiModel } from './utils/ai'
import { stringArrayToLocaleArray } from './utils/locales'

interface FlowBuilderProps {
  repository: Repository
}

const FlowBuilder = ({ repository }: FlowBuilderProps): JSX.Element => {
  const { reportFlowPublished, reportVersionRestored, reportVersionNotLoaded } =
    useFlowBuilderFeedback()
  const { reportInform, reportError } = useFeedbackMessage()
  const {
    state,
    removeFeedbackMessages,
    restoreChangeHistory,
    selectAiModel,
    selectLocale,
    setBotConfig,
    setBotVariables,
    setFlows,
    setHash,
    setHashPublished,
    setKnowledgeBaseActive,
    setLoadingMessage,
    setLocales,
    setOrganizationContents,
    setPopupContent,
    toggleFlowSaved,
    toggleInteractivity,
    setCurrentVersion,
  } = useFlowBuilderSelector(ctx => ctx)

  const authToken = state.authToken

  const analytics = useAnalytics()
  const { saveFlow } = useSave(repository)
  const [flowContent, setFlowContent] = useState<FlowContent>()
  const [versionHistoryData, setVersionHistoryData] = useState<
    VersionHistoryData[]
  >([])

  const { sessionState } = usePusher(authToken)

  const [isPublished, setIsPublished] = useState(false)
  useEffect(() => {
    if (state.hash) {
      setIsPublished(state.hash === state.hashPublished)
      return
    }
  }, [state.hash, state.hashPublished])

  useEffect(() => {
    const isFlowAlreadyLoaded = state.nodes.length > 0
    if (
      sessionState.userLoggingIn &&
      sessionState.currentlyActiveUserId &&
      !isFlowAlreadyLoaded
    ) {
      setLoadingMessage(LoadingMessage.LOADING_CONTENT)
      if (sessionState.resolution === SessionResolution.NoConflict) {
        const resolvingSessionTimeout =
          sessionState.resolution !== sessionState.previousResolution
            ? SESSION_RESOLUTION_TIME
            : 0

        setTimeout(() => {
          loadInitialFlow()
          getVersionHistoryData()
        }, resolvingSessionTimeout)
        return
      }
      if (sessionState.resolution === SessionResolution.Conflict) {
        setPopupContent({
          type: PopupType.SESSION_CONFLICT,
          activeUserId: sessionState.currentlyActiveUserId,
          isAnotherUser: sessionState?.isAnotherUser ?? false,
          onConfirm: () => sessionState.logUserOut && sessionState.logUserOut(),
          onDiscard: () => sessionState.logMeOut && sessionState.logMeOut(),
          closeData: null,
        })
        return
      }

      if (sessionState.resolution === SessionResolution.Ended) {
        const _save = async () => {
          await save(SaveOrigin.ON_SESSION_ENDED)
        }
        _save()
        setPopupContent({
          type: PopupType.SESSION_ENDED,
          activeUserId: sessionState.currentlyActiveUserId,
          closeData: null,
        })
        return
      }
    }
  }, [sessionState])

  const loadInitialFlow = async (): Promise<void> => {
    if (sessionState.userLoggingIn) {
      analytics.identify(sessionState.userLoggingIn) // TODO: Investigate why app is not working with await here
      const orgContents = await HubtypeService.getOrganizationContents(
        authToken,
        sessionState.userLoggingIn.botId
      )
      setOrganizationContents(orgContents)

      await Promise.all([
        loadFlow(undefined, DRAFT_VERSION, orgContents),
        loadPublishedHash(orgContents),
      ])

      const botConfig = await HubtypeService.getBotConfig(
        authToken,
        sessionState.userLoggingIn.botId
      )
      if (botConfig) {
        setBotConfig(botConfig)
      }
      toggleFlowSaved(true)
    }
  }

  const loadPublishedHash = async (
    orgContents: OrganizationContents
  ): Promise<void> => {
    const publishedFlow = await repository.cmsReader.readFlowContent(
      authToken,
      undefined,
      orgContents,
      LATEST_VERSION
    )

    setHashPublished(publishedFlow?.hash || '')
    return
  }

  const loadFlow = async (
    locale: Locale | undefined,
    version: string,
    organizationContents: OrganizationContents
  ): Promise<void> => {
    const newFlowContent = await repository.cmsReader.readFlowContent(
      authToken,
      locale,
      organizationContents,
      version
    )
    if (!newFlowContent) {
      reportVersionNotLoaded()
      setLoadingMessage(undefined)
      return
    }
    const aiModel = getFlowAiModel(newFlowContent, organizationContents)
    await loadAiModel(aiModel)
    if (newFlowContent.locales.length) {
      const locales = stringArrayToLocaleArray(newFlowContent.locales)
      setLocales(locales)
      if (!locale) selectLocale(locales[0], false)
      if (version === DRAFT_VERSION) {
        const botId = organizationContents.conversationalApp.id
        setFBAppStoragePreviewState(botId, {
          hasFlowErrors: false,
          current: locales[0],
          locales,
        })
      }
    }
    setKnowledgeBaseActive(newFlowContent.isKnowledgeBaseActive, false)
    setFlows(newFlowContent.flows)
    setBotVariables(newFlowContent.botVariables)
    setFlowContent(newFlowContent)
    setLoadingMessage(undefined)
    setHash(newFlowContent.hash)
    if (version === DRAFT_VERSION) {
      KnowledgeBaseFields.trackInvalidSources(newFlowContent.nodes, analytics)
    }
  }

  const save = async (
    origin?: SaveOrigin,
    isRestoring?: boolean
  ): Promise<boolean> => {
    if (isRestoring) setLoadingMessage(LoadingMessage.RESTORING_VERSION)
    const isSaved = await saveFlow(origin, isRestoring)

    const restoredVersion = isRestoring ? state.currentVersion : undefined
    restoredVersion && reportVersionRestored(isSaved, restoredVersion.createdAt)
    if (isRestoring) setLoadingMessage(undefined)
    return isSaved
  }

  const publish = async () => {
    setLoadingMessage(LoadingMessage.PUBLISH)
    let isSaved = true
    if (!state.isFlowSaved) {
      isSaved = await save(SaveOrigin.BEFORE_PUBLISH)
    }
    const hasFlowErrors = state.nodes.some(
      node =>
        node.data.errors.fieldErrors.length ||
        node.data.errors.hasDuplicatedCode
    )
    if (hasFlowErrors) {
      reportInform('Fix errors to publish')
    }
    if (isSaved && !hasFlowErrors) {
      removeFeedbackMessages()
      const publishedStatus =
        await repository.cmsEnvironment.publishFlow(authToken)
      if (publishedStatus === FeedbackType.SUCCESS) {
        setHashPublished(state.hash)
        getVersionHistoryData()
      }
      reportFlowPublished(publishedStatus)
    }
    setLoadingMessage(undefined)
  }

  const exit = async (closeData?: string): Promise<void> => {
    await analytics.trackEvent(TrackEventName.EXIT, {
      origin: closeData ? 'nav_bar' : 'exit',
    })
    if (!state.isFlowSaved) {
      setLoadingMessage(LoadingMessage.SAVE)
      await save(SaveOrigin.ON_EXIT)
      setLoadingMessage(undefined)
    }
    postCloseFromFlowBuilderMessage(closeData || null)
  }

  const uploadFile = async (file: File) => {
    const asset = await repository.cmsWriter.uploadFile(authToken, file)
    if (!asset) {
      reportError('Error uploading image')
    }
    return asset
  }

  const loadAiModel = async (aiModel?: OrganizationAiModel): Promise<void> => {
    if (!aiModel) selectAiModel(undefined)
    else {
      const orgAiIntents = await getAiModelIntents(aiModel, authToken)
      selectAiModel({ ...aiModel, intents: orgAiIntents })
    }
  }

  const getVersionHistoryData = async (): Promise<void> => {
    const versionData = await FlowBuilderService.getVersionHistory(authToken)
    if (!Array.isArray(versionData)) return
    const dataWithUserInfo = await populateVersionsUserInfo(
      versionData,
      authToken
    )
    setVersionHistoryData(dataWithUserInfo)
  }

  const populateVersionsUserInfo = async (
    versionData: VersionHistoryData[],
    authToken: string
  ): Promise<VersionHistoryData[]> => {
    const uniqueUserIds = [...new Set(versionData.map(user => user.createdBy))]
    const userInfo: Record<string, HubtypeUser | undefined> = {}
    const promises = uniqueUserIds.map(id =>
      HubtypeService.getUserData(authToken, id)
    )
    const usersData = await Promise.all(promises)
    usersData.forEach(data => {
      if (data) userInfo[data.id] = data
    })
    return versionData.map(data => ({
      ...data,
      user: userInfo[data.createdBy],
    }))
  }

  const loadPublishedVersion = async (
    version: VersionHistoryData
  ): Promise<void> => {
    setLoadingMessage(LoadingMessage.LOADING_VERSION)
    if (!state.isFlowSaved) {
      await save(SaveOrigin.ON_OPEN_PREVIOUS_VERSION)
    }
    restoreChangeHistory()
    removeFeedbackMessages()
    await loadFlow(state.currentLocale, version.id, state.organizationContents)
    setLoadingMessage(undefined)
    toggleInteractivity(false)
    toggleFlowSaved(true)
    setCurrentVersion(version)
  }

  const restoreDraftFlow = async (): Promise<void> => {
    setCurrentVersion(undefined)
    toggleInteractivity(true)
    setLoadingMessage(LoadingMessage.LOADING_CONTENT)
    await loadFlow(
      state.currentLocale,
      DRAFT_VERSION,
      state.organizationContents
    )
  }

  const restoreVersion = async (): Promise<void> => {
    toggleInteractivity(true)
    await save(undefined, true)
    setCurrentVersion(undefined)
  }

  const generateContentIdWithAi = async (): Promise<
    AiGeneratedContentId[] | undefined
  > => {
    if (!authToken) return undefined
    const nodesWithoutContentId = state.nodes.filter(
      node =>
        !node.data.code &&
        (node.type === ContentType.TEXT ||
          node.type === ContentType.WHATSAPP_BUTTON_LIST ||
          node.type === ContentType.WHATSAPP_CTA_URL_BUTTON)
    ) as (TextNode | WhatsappButtonListNode)[]
    return await Promise.all(
      nodesWithoutContentId.map(async node => {
        const newContentId = await FlowBuilderService.generateContentIdWithAi(
          authToken,
          node.data.text
        )
        return { nodeId: node.id, newContentId }
      })
    )
  }

  return (
    <>
      {sessionState.resolution === SessionResolution.NoConflict && (
        <ReactFlowProvider>
          <VersionBanner restoreDraftFlow={restoreDraftFlow} />
          {!state.isReadOnly && !state.loadingMessage && (
            <FeedbackPanel generateContentIdWithAi={generateContentIdWithAi} />
          )}
          {flowContent && (
            <FlowContainer id='flow-container'>
              <Header
                isPublished={isPublished}
                onSave={save}
                onExit={exit}
                versionHistoryData={versionHistoryData}
                loadPublishedVersion={loadPublishedVersion}
              />
              {state.isLocalesPanelOpen && <LocalesPanel />}
              <Flow {...flowContent} />
              {state.currentNode && <NodeEditorPanel uploadFile={uploadFile} />}
            </FlowContainer>
          )}
        </ReactFlowProvider>
      )}
      {state.popupContent &&
        renderPopup(state.popupContent, publish, loadAiModel, restoreVersion)}
    </>
  )
}
export default FlowBuilder
