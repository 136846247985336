import { QueueFields } from './content-fields'

export interface OrganizationProject {
  id: string
  name: string
  queues: QueueFields[]
}

export interface OrganizationQueue {
  id: string
  name: string
}

export interface OrganizationAiModel {
  id: string
  name: string
}

export interface OrganizationAiModelWithIntents extends OrganizationAiModel {
  intents: OrganizationAiIntent[]
}

export interface OrganizationAiIntent {
  id: string
  name: string
}

export interface OrganizationKnowledgeBases {
  count: number
  next: number | null
  previous: number | null
  results: KnowledgeBasesResult[]
}

interface KnowledgeBasesResult {
  id: string
  name: string
  knowledge_sources: OldKnowledgeSourceResponse[]
  is_main: boolean
}

interface OldKnowledgeSourceResponse {
  id: string
  file_name: string
  is_active: boolean
}

export enum KnowledgeSourceType {
  FILE = 'file',
  URL = 'url',
}

export interface KnowledgeSourceResponse {
  id: string
  name: string
  type: KnowledgeSourceType
  active_extraction_job?: {
    file_name: string | null
    file_url: string | null
    url: string | null
    id: string
    is_active: boolean
  }
}

export interface OrganizationKnowledgeSource {
  id: string
  name: string
  fileType?: string
  isInvalid?: boolean
}

export class HubtypeUser {
  constructor(
    public id: string,
    public email: string,
    public username?: string,
    public firstName?: string,
    public lastName?: string,
    public pic?: string
  ) {}

  getUserName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }
    return this.username || this.email
  }
}

export interface FlowBuilderUser {
  userId: string
  userRole: string
  organizationId: string
  botId: string
}

export enum Provider {
  WEBCHAT = 'webchat',
  WHATSAPP = 'whatsapp',
}

export class ConversationalAppProviderAccount {
  constructor(
    public id: string,
    public provider: Provider,
    public name: string,
    public isTest: boolean,
    public isActive: boolean,
    public phoneNumber: string,
    public netlifyUrl: string
  ) {}
}

interface FlowBuilderSettings {
  isPreviewEnabled?: boolean
}

export class ConversationalApp {
  constructor(
    public id?: string,
    public name?: string,
    public providerAccounts?: ConversationalAppProviderAccount[],
    public flowBuilderSettings?: FlowBuilderSettings
  ) {
    this.providerAccounts = []

    const providerTestAccounts = providerAccounts?.filter(
      providerAccount => providerAccount.isTest
    )

    const webchatTestAccount = providerTestAccounts?.find(
      providerAccount => providerAccount.provider === Provider.WEBCHAT
    )
    if (webchatTestAccount) {
      this.providerAccounts.push(webchatTestAccount)
    }

    const whatsappTestAccount = providerTestAccounts?.find(
      providerAccount => providerAccount.provider === Provider.WHATSAPP
    )
    if (whatsappTestAccount) {
      this.providerAccounts.push(whatsappTestAccount)
    }
  }
}
