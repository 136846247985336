import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { PopupType } from '../../../types'
import { Popup } from '../popup'

interface PublishPopupProps {
  onPublish(): Promise<void>
}

export const PublishPopup = ({ onPublish }: PublishPopupProps): JSX.Element => {
  const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)
  const analytics = useAnalytics()

  const publish = async (): Promise<void> => {
    setPopupContent()
    await Promise.all([
      analytics.trackEvent(TrackEventName.PUBLISH_CONFIRMATION, {
        has_published: true,
      }),
      onPublish(),
    ])
  }

  const cancel = async (): Promise<void> => {
    await analytics.trackEvent(TrackEventName.PUBLISH_CONFIRMATION, {
      has_published: false,
    })
  }

  return (
    <Popup
      title={PopupType.PUBLISH}
      cancel={cancel}
      confirmButton={{ text: 'Publish', onClick: publish }}
      message={
        "Please note: If you go ahead, you'll be replacing the current live version. You can always find older versions from the 'Version history' dropdown."
      }
    />
  )
}
