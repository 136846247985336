import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { AVAILABLE_COUNTRIES } from '../../../UI/constants'
import { IdMapping } from '../../../UI/types'
import { Feedback } from '../../feedback'
import { HtNodeLink } from '../cms/hubtype/common'
import { HtFunctionNode } from '../cms/hubtype/function'
import { HtContentType } from '../cms/hubtype/node-types'
import { Country } from '../locales/country'
import { ContentType, TopContentFieldsBase } from '.'
import { CONDITIONAL_FUNCTIONS } from './common'
import { ConditionalContentType } from './content-types'

export interface CountryInfo {
  id: string
  name: Country
}

export class CountryConditionFields extends TopContentFieldsBase {
  constructor(public countries: CountryInfo[] = []) {
    super()
  }

  contentType(): ConditionalContentType {
    return ContentType.COUNTRY_CONDITION
  }

  stringContentType(): string {
    return 'country condition'
  }

  static fromHubtypeCMS(component: HtFunctionNode): CountryConditionFields {
    const newCountryCondition = new CountryConditionFields()
    this.setHubtypeCmsCommonData(newCountryCondition, component)
    component.content.result_mapping.forEach(result => {
      const countryFound = AVAILABLE_COUNTRIES.find(
        country => country.id === result.result
      )
      if (countryFound) {
        newCountryCondition.countries.push(countryFound)
      }
    })
    return newCountryCondition
  }

  toHubtypeCMS(position: XYPosition): HtFunctionNode {
    const node: HtFunctionNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.FUNCTION,
      content: {
        action: CONDITIONAL_FUNCTIONS.CHECK_COUNTRY,
        arguments: [],
        result_mapping: [],
      },
    }
    return node
  }

  reportErrors(
    feedback: Feedback,
    defaultTarget: HtNodeLink | undefined
  ): boolean {
    const errors = []
    if (this.countries.length === 0) errors.push('countries')
    if (!defaultTarget) errors.push('default target')
    if (errors.length === 0) return false
    this.reportMissingFields(errors, feedback)
    return true
  }

  setErrors(): void {
    const requiredFields = []
    if (this.countries.length === 0) requiredFields.push('countries')
    if (!this.edges.some(edge => edge.sourceHandle?.includes('default'))) {
      requiredFields.push('other')
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.code], value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return this.getConditionalConnections(this.countries, edges)
  }

  static getCopy(content: CountryConditionFields): CountryConditionFields {
    const newCountryCondition = new CountryConditionFields()
    TopContentFieldsBase.copyContent(content, newCountryCondition)
    return newCountryCondition
  }

  getIdMappingForOldFields(oldCountries: CountryConditionFields): IdMapping {
    return this.getConditionalIdMappings(
      this.countries,
      oldCountries.countries,
      oldCountries.id
    )
  }
}
