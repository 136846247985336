import { memo } from 'react'

import { CountryConditionFields } from '../../../../../domain/models/content-fields'
import { AVAILABLE_COUNTRIES } from '../../../../constants'
import { TextBodyBold } from '../../../base'
import { HtInfoPanel } from '../../../base/ht-info-panel/ht-info-panel'
import { COUNTRIES } from '../../constants'
import { SortableDropdownWidgetField } from '../../editor-widgets/sortable-dropdown-widget'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'

interface CountryEditorProps extends EditorProps {
  nodeContent: CountryConditionFields
}

export const CountryConditionEditor = memo(
  ({ nodeContent, ...props }: CountryEditorProps): JSX.Element => {
    return (
      <>
        <TextBodyBold>Country</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
          isOptional={true}
        />
        <SortableDropdownWidgetField
          {...props}
          nodeContent={nodeContent}
          contentType={nodeContent.contentType()}
          applyButtonText='Add countries'
          field={COUNTRIES}
          values={nodeContent.countries}
          availableItems={AVAILABLE_COUNTRIES}
        ></SortableDropdownWidgetField>
        <HtInfoPanel
          title='About country condition'
          text={
            'Use this condition to set different flows for specific countries, for example, to show a different policy depending on the country where the user is located despite their language.'
          }
        ></HtInfoPanel>
      </>
    )
  }
)
