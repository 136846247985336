import { Edge, XYPosition } from 'reactflow'

import { Feedback } from '../../domain/feedback'
import { HtNodeLink } from '../../domain/models/cms/hubtype/common'
import { HtFunctionNode } from '../../domain/models/cms/hubtype/function'
import { HtContentType } from '../../domain/models/cms/hubtype/node-types'
import {
  CONDITIONAL_FUNCTIONS,
  ConditionalContentType,
  ContentType,
  TopContentFieldsBase,
} from '../../domain/models/content-fields'
import { Icon } from '../../UI/components/base'
import { NodeEdges } from '../../UI/components/edges/edge-utils'
import { IdMapping } from '../../UI/types'
import { ConditionValueFields } from './condition-value/model'

export interface ConditionValue {
  id: string
  name: string
}

export enum VariableFormat {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
}

export const AVAILABLE_VARIABLE_FORMATS = [
  { id: VariableFormat.STRING, icon: Icon.FONT },
  { id: VariableFormat.BOOLEAN, icon: Icon.TOGGLE_ON },
  { id: VariableFormat.NUMBER, icon: Icon.NUMBER },
]

export class CustomConditionFields extends TopContentFieldsBase {
  public variableFormat?: VariableFormat
  public selectedSubContentId?: string

  constructor(
    public values: ConditionValueFields[] = [],
    public conditionVariable = ''
  ) {
    super()
  }

  contentType(): ConditionalContentType {
    return ContentType.CUSTOM_CONDITION
  }

  stringContentType(): string {
    return 'custom condition'
  }

  static fromHubtypeCMS(component: HtFunctionNode): CustomConditionFields {
    const newCustomConditional = new CustomConditionFields()
    this.setHubtypeCmsCommonData(newCustomConditional, component)
    component.content.result_mapping.forEach(result => {
      if (result.result !== 'default') {
        newCustomConditional.values.push(
          new ConditionValueFields(result.result.toString())
        )
      }
    })
    if ('value' in component.content.arguments[0]) {
      newCustomConditional.conditionVariable =
        component.content.arguments[0].value.toString()
      //@ts-ignore
      newCustomConditional.variableFormat = component.content.arguments[0].type
    }

    return newCustomConditional
  }

  toHubtypeCMS(position: XYPosition): HtFunctionNode {
    const node: HtFunctionNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.FUNCTION,
      content: {
        action: CONDITIONAL_FUNCTIONS.CHECK_BOT_VARIABLE,
        arguments: [
          {
            //@ts-ignore
            type: this.variableFormat || VariableFormat.STRING,
            name: 'keyPath',
            value: this.conditionVariable,
          },
        ],
        result_mapping: [],
      },
    }
    return node
  }

  reportErrors(
    feedback: Feedback,
    defaultTarget: HtNodeLink | undefined
  ): boolean {
    const errors = []
    if (!this.conditionVariable) errors.push("'if'")
    if (!this.variableFormat) errors.push('variable format')
    if (!defaultTarget && this.variableFormat !== VariableFormat.BOOLEAN) {
      errors.push('default target')
    }
    if (this.values.some(value => value.name === '')) {
      errors.push("'is'")
    }

    this.values.forEach(value => {
      if (
        value.name &&
        this.variableFormat === VariableFormat.NUMBER &&
        isNaN(Number(value.name))
      ) {
        this.reportError(
          `Error in ${this.contentType()} with id "${this.code}": field 'is ${
            value.name
          }' must be a number.`,
          this.id,
          feedback
        )
      }
    })
    if (errors.length === 0) return false
    this.reportMissingFields(errors, feedback)
    return true
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.conditionVariable) requiredFields.push("'if'")
    if (!this.variableFormat) requiredFields.push('variable format')
    if (this.values.some(value => value.name === '')) {
      requiredFields.push("'is'")
    }
    if (
      this.variableFormat !== VariableFormat.BOOLEAN &&
      !this.edges.some(edge => edge.sourceHandle?.includes('default'))
    ) {
      requiredFields.push('otherwise')
    }

    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    const valuesErrors = this.values.flatMap(value =>
      value.getErrors(this.code, this.variableFormat)
    )
    this.errors.fieldErrors = [...fieldErrors, ...valuesErrors]
  }

  hasString(value: string): boolean {
    if (this.fieldsIncludeString([this.code, this.conditionVariable], value)) {
      return true
    }
    const includesString = this.values.map(v => v.hasString(value))
    return includesString.indexOf(true) !== -1
  }

  getConnections(edges: Edge[]): NodeEdges {
    return this.getConditionalConnections(this.values, edges)
  }

  static getCopy(content: CustomConditionFields): CustomConditionFields {
    const newCustomCondition = new CustomConditionFields()
    TopContentFieldsBase.copyContent(content, newCustomCondition)
    newCustomCondition.values = content.values.map(value =>
      ConditionValueFields.getCopy(value)
    )
    return newCustomCondition
  }

  getIdMappingForOldFields(
    oldCustomCondition: CustomConditionFields
  ): IdMapping {
    return this.getConditionalIdMappings(
      this.values,
      oldCustomCondition.values,
      oldCustomCondition.id
    )
  }
}
