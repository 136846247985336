import { useEffect, useRef, useState } from 'react'

import { FeedbackType } from '../../../domain/feedback'
import { TrackEventName, useAnalytics } from '../../analytics'
import { useConnectionStatus } from '../../custom-hooks'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import {
  FadeInOutContainer,
  FadeOutContainer,
  HtIcon,
  HtSnackbar,
  HtTooltip,
  Icon,
  Size,
} from '../base'
import { TooltipPlacement } from '../base/ht-tooltip/ht-tooltip-styles'
import { OFFLINE_TIMEOUT, ONLINE_TIMEOUT } from './flow-action-buttons'
import { ConnectionStatusContainer } from './header-styles'

export const ConnectionStatus = (): JSX.Element => {
  const analytics = useAnalytics()
  const { toggleIsOffline } = useFlowBuilderSelector(ctx => ctx)

  const [isConnectionInstable, toggleConnectionInstability] = useState(false)
  const [isSnackbarVisible, toggleSnackbarVisibility] = useState(false)
  const [isOnlineIconVisible, toggleOnlineIconVisibility] = useState(false)
  const offlineTimeoutRef = useRef<any>(null)
  const onlineTimeoutRef = useRef<any>(null)

  useEffect(() => {
    toggleSnackbarVisibility(isConnectionInstable)
  }, [isConnectionInstable])

  const onOnline = () => {
    analytics.trackEvent(TrackEventName.CONNECTION_RECOVERED, {
      recovered_less_10sec: !isConnectionInstable,
    })
    handleOnlineStatusChange()
    toggleConnectionInstability(false)
    toggleIsOffline(false)
    if (!isConnectionInstable) return
    toggleOnlineIconVisibility(true)
    onlineTimeoutRef.current = setTimeout(() => {
      toggleOnlineIconVisibility(false)
    }, ONLINE_TIMEOUT)
  }

  const onOffline = () => {
    handleOnlineStatusChange()
    toggleIsOffline(true)
    offlineTimeoutRef.current = setTimeout(() => {
      toggleConnectionInstability(true)
    }, OFFLINE_TIMEOUT)
  }

  const handleOnlineStatusChange = () => {
    clearTimeout(offlineTimeoutRef.current)
    clearTimeout(onlineTimeoutRef.current)
  }

  useConnectionStatus(onOnline, onOffline)

  return (
    <>
      <HtTooltip
        tooltip='Your connection seems to not be working. Saving and publishing will only be enabled when connection is reestablished.'
        isDisabled={isSnackbarVisible || !isConnectionInstable}
        placement={TooltipPlacement.BOTTOM}
      >
        <ConnectionStatusContainer>
          <FadeInOutContainer $isActive={isOnlineIconVisible}>
            <HtIcon icon={Icon.CLOUD_CHECK} size={Size.LARGE} />
          </FadeInOutContainer>
          <FadeOutContainer $isActive={isConnectionInstable}>
            <HtIcon icon={Icon.CLOUD_SLASH} size={Size.LARGE} />
          </FadeOutContainer>
        </ConnectionStatusContainer>
      </HtTooltip>
      {isConnectionInstable && isSnackbarVisible && (
        <HtSnackbar
          title='Your connection seems to not be working.'
          text='Saving and publishing will only be enabled when connection is reestablished.'
          type={FeedbackType.CONNECTION_ISSUE}
          onClose={() => toggleSnackbarVisibility(false)}
        />
      )}
    </>
  )
}
