import { Feedback } from '../domain/feedback'
import { CMSEnvironment, CMSReader, CMSWriter } from '../domain/models/cms'
import { HubtypeEnvironment } from './environment/hubtype-environment'
import { HubtypeReader } from './read/hubtype-reader'
import { HubtypeWriter } from './write/hubtype-writer'

export interface Repository {
  cmsReader: CMSReader
  cmsWriter: CMSWriter
  cmsEnvironment: CMSEnvironment
}

export const createRepository = (feedback: Feedback): Repository => {
  const cmsReader = new HubtypeReader(feedback)
  const cmsWriter = new HubtypeWriter(feedback)
  const cmsEnvironment = new HubtypeEnvironment()
  return { cmsReader, cmsWriter, cmsEnvironment }
}
