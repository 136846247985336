import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { Feedback } from '../../feedback'
import { HtNodeLink } from '../cms/hubtype/common'
import { HtKeywordNode } from '../cms/hubtype/keyword'
import { HtContentType } from '../cms/hubtype/node-types'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import { ContentType, InputContentType, TopContentFieldsBase } from '.'
import { InputLocale, TextLocale } from './common'

export class KeywordFields extends TopContentFieldsBase {
  constructor(
    public title: string = '',
    public keywords: string[] = [],
    public titleLocales: TextLocale[] = [],
    public keywordsLocales: InputLocale[] = []
  ) {
    super()
  }

  contentType(): InputContentType {
    return ContentType.KEYWORD
  }

  stringContentType(): string {
    return 'keyword'
  }

  setValuesByLocale(locale: string): void {
    this.title = LocaleManager.getTextByLocale(this.titleLocales, locale)
    this.keywords = LocaleManager.getInputsByLocale(
      this.keywordsLocales,
      locale
    )
  }

  setContentByLocale(locale: LocaleCode): void {
    this.keywordsLocales = LocaleManager.setInputsByLocale(
      this.keywordsLocales,
      this.keywords,
      locale
    )
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.titleLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.keywordsLocales, newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.titleLocales, localesToRemove)
    LocaleManager.removeLocales(this.keywordsLocales, localesToRemove)
  }

  static fromHubtypeCMS(
    component: HtKeywordNode,
    locale: string
  ): KeywordFields {
    const newKeyword = new KeywordFields()
    this.setHubtypeCmsCommonData(newKeyword, component)
    newKeyword.titleLocales = component.content.title
    newKeyword.keywordsLocales = component.content.keywords
    newKeyword.setValuesByLocale(locale)
    newKeyword.code = component.code || newKeyword.title

    return newKeyword
  }

  toHubtypeCMS(position: XYPosition, locale: LocaleCode): HtKeywordNode {
    this.setContentByLocale(locale)
    const node: HtKeywordNode = {
      ...this.getHubtypeCmsCommonData(position),
      code: '',
      type: HtContentType.KEYWORD,
      content: {
        title: this.titleLocales,
        keywords: this.keywordsLocales,
      },
    }
    return node
  }

  reportErrors(feedback: Feedback, target?: HtNodeLink): boolean {
    const errors = []
    if (this.keywords.length === 0) errors.push('keywords')
    if (!target) errors.push('target')
    if (errors.length === 0) return false
    this.reportMissingFields(errors, feedback)
    return true
  }

  setErrors(): void {
    const requiredFields = []
    if (this.keywords.length === 0) requiredFields.push('keywords')
    if (!this.edges.some(edge => edge.sourceHandle?.includes(this.id))) {
      requiredFields.push('target')
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors = LocaleManager.getLocalesWithEmptyInput(
      this.keywordsLocales,
      locales
    )
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString(this.keywords, value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(content: KeywordFields): KeywordFields {
    const newKeywords = new KeywordFields()
    TopContentFieldsBase.copyContent(content, newKeywords)
    return newKeywords
  }
}
