export enum CountryCode {
  AT = 'AT',
  BG = 'BG',
  CA = 'CA',
  CN = 'CN',
  CO = 'CO',
  HR = 'HR',
  CZ = 'CZ',
  DK = 'DK',
  EE = 'EE',
  FI = 'FI',
  FR = 'FR',
  GE = 'GE',
  DE = 'DE',
  GR = 'GR',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IE = 'IE',
  IT = 'IT',
  JP = 'JP',
  KR = 'KR',
  LV = 'LV',
  LT = 'LT',
  LU = 'LU',
  MY = 'MY',
  MT = 'MT',
  MX = 'MX',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  RU = 'RU',
  SA = 'SA',
  RS = 'RS',
  SK = 'SK',
  SI = 'SI',
  ZA = 'ZA',
  ES = 'ES',
  LK = 'LK',
  SE = 'SE',
  CH = 'CH',
  TH = 'TH',
  TR = 'TR',
  UA = 'UA',
  GB = 'GB',
  US = 'US',
  VN = 'VN',
}
