import { v7 as uuidv7 } from 'uuid'

import { NodeTypes } from '../UI/types'
import { normalizeValue } from '../utils/string-utils'
import { ContentType } from './models/content-fields'

export enum FeedbackType {
  CONNECTION_ISSUE = 'connection-issue',
  ERROR = 'error',
  INFORM = 'inform',
  SUCCESS = 'success',
  CUSTOM = 'custom',
}

export interface FeedbackDetails {
  nodeId?: string
  localeCode?: string
  isAiNotification?: boolean
}

export class FeedbackMessage {
  public readonly id: string
  constructor(
    public message: string,
    public type: FeedbackType,
    public details?: FeedbackDetails
  ) {
    this.id = uuidv7()
  }
}

// TODO: remove this class in future refactor (now it's only used to show feedback when reading and writing content to s3)
export class Feedback {
  reportRepeatedContentIds(nodes: NodeTypes[]) {
    const codeCount = nodes.reduce((acc: Record<string, number>, node) => {
      const code = normalizeValue(node.data.code)
      acc[code] = (acc[code] || 0) + 1
      return acc
    }, {})

    nodes.forEach(node => {
      if (
        node.data.code &&
        codeCount[normalizeValue(node.data.code)] > 1 &&
        node.type !== ContentType.GO_TO_FLOW
      ) {
        this.reportError(
          `Error in ${node.type}: content ID "${node.data.code}" already exists`,
          { nodeId: node.id }
        )
      }
    })
  }

  reportError(message: string, options?: FeedbackDetails): void {
    // TODO: remove class and calls of this method (their are no longer used)
  }
}
