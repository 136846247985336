import { useRef, useState } from 'react'

import {
  ConversationalAppProviderAccount,
  Provider,
} from '../../../../domain/models/organization-models'
import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { HtBtnType, HtButton, Size } from '../../base'
import { PreviewContainer } from './preview-styles'
import {
  arraySortAlphabetically,
  generateUrlForWhatsappIntegration,
  generateUrlsForWebchatIntegration,
  openURLInNewTab,
  openURLInNewWindowWithDimensions,
  WEBCHAT_PREVIEW_FEATURES,
} from './utils'

interface FlowActionPreviewButtonProps {
  blurButton: (event: React.MouseEvent<HTMLButtonElement>) => void
  exit: (closeData: string) => void
}

export const FlowActionPreviewButton = ({
  blurButton,
  exit,
}: FlowActionPreviewButtonProps): JSX.Element => {
  const {
    organizationContents: { conversationalApp },
    currentLocale,
    locales,
  } = useFlowBuilderSelector(ctx => ctx.state)

  const analytics = useAnalytics()
  //const [isOpen, setIsOpen] = useState<boolean>(false)

  const [clickedCopyLink, _setClickedCopyLink] = useState(false)
  const isClickedCopyLinkRef = useRef(clickedCopyLink)

  // TODO: We have to find a solution when using Select-Item renderer and allow item customization of the dropdown
  const setClickedCopyLink = (clickedStatus: boolean) => {
    isClickedCopyLinkRef.current = clickedStatus
    _setClickedCopyLink(clickedStatus)
  }

  // const onOpenChange = (isOpen: boolean) => {
  //   setIsOpen(isOpen)
  //   if (!isOpen) return
  //   analytics.trackEvent(TrackEventName.PREVIEW)
  // }

  //const [renderCopiedTooltip, setRenderCopiedTooltip] = useState(false)
  // const handleShowCopiedLinkTooltip = (): void => {
  //   setRenderCopiedTooltip(true)
  //   setTimeout(() => setRenderCopiedTooltip(false), 1000)
  // }

  const onSelectedIntegration = (
    selectedProvider: ConversationalAppProviderAccount | undefined
  ): void => {
    if (isClickedCopyLinkRef.current && selectedProvider) {
      analytics.trackEvent(TrackEventName.PREVIEW_COPY_LINK, {
        integration_type: selectedProvider.provider,
      })
    }
    if (!isClickedCopyLinkRef.current && selectedProvider) {
      if (selectedProvider.provider === Provider.WEBCHAT) {
        const { previewUrl } = generateUrlsForWebchatIntegration(
          conversationalApp,
          selectedProvider,
          currentLocale.code,
          locales
        )
        openURLInNewWindowWithDimensions(previewUrl, WEBCHAT_PREVIEW_FEATURES)
      }
      if (selectedProvider.provider === Provider.WHATSAPP) {
        const previewUrl = generateUrlForWhatsappIntegration(selectedProvider)
        openURLInNewTab(previewUrl)
      }
      analytics.trackEvent(TrackEventName.PREVIEW_OPEN, {
        integration_type: selectedProvider.provider,
      })
    }
    setClickedCopyLink(false)
    //handleShowCopiedLinkTooltip()
  }

  // const handleGoToIntegrations = (event: any): void => {
  //   setIsOpen(false)
  //   blurButton(event)
  //   analytics.trackEvent(TrackEventName.PREVIEW_INTEGRATIONS_SECTION)
  //   const closeData = `/bots/${conversationalApp.id}/integrations`
  //   exit(closeData)
  // }

  const integrationsData = arraySortAlphabetically(
    conversationalApp.providerAccounts || []
  ).filter(item => item.isTest)

  // const SelectDropdownPreviewFooter = () => {
  //   const onlyHasWebchatIntegration =
  //     integrationsData?.length === 1 &&
  //     integrationsData[0].provider === Provider.WEBCHAT

  //   if (onlyHasWebchatIntegration) {
  //     return (
  //       <StyledFooter>
  //         <TextSmallExtra>
  //           To preview on WhatsApp start by adding a WhatsApp Playground
  //           integration in the{' '}
  //           <StyledInlineTextLink onClick={handleGoToIntegrations}>
  //             integrations section
  //           </StyledInlineTextLink>
  //           .
  //         </TextSmallExtra>
  //       </StyledFooter>
  //     )
  //   }
  //   return null
  // }

  const openWebchatPreview = () => {
    const webchatIntegration = integrationsData.find(
      integration => integration.provider === Provider.WEBCHAT
    )
    if (webchatIntegration) {
      onSelectedIntegration(webchatIntegration)
    }
  }

  // const isReadOnly = conversationalApp.providerAccounts?.length === 0
  return (
    <PreviewContainer>
      {/* // TODO: reactivate preview menu dropdown */}
      <HtButton
        type={HtBtnType.TERTIARY}
        size={Size.TINY}
        onClick={openWebchatPreview}
      >
        Preview
      </HtButton>
      {/* <HtMenuDropdown
        isOpen={isOpen}
        isDisabled={isReadOnly}
        hideLabel={true}
        listProps={{
          maxListHeight: '450px',
          hasItemsWithBorder: true,
          itemSize: Size.LARGE,
          listPlacement: Placement.BOTTOM_END,
        }}
        btnProps={{
          type: HtBtnType.TERTIARY,
          size: Size.TINY,
          width: 'fit-content',
          tooltip: 'Save to preview latest changes',
        }}
        readOnly={isReadOnly}
        data={integrationsData}
        label='Preview'
        onChange={onSelectedIntegration}
        onOpenChange={onOpenChange}
        footer={<SelectDropdownPreviewFooter />}
      >
        {integration => (
          <IntegrationItem
            conversationalApp={conversationalApp}
            integration={integration}
            onCopyLinkClick={() => setClickedCopyLink(true)}
          />
        )}
      </HtMenuDropdown>
      {renderCopiedTooltip && (
        <StyledTooltip
          title={'Link copied'}
          placement='bottom-start'
          open={true}
        >
          <div></div>
        </StyledTooltip>
      )} */}
    </PreviewContainer>
  )
}
