import { useEffect, useRef, useState } from 'react'

import { SaveOrigin, TrackEventName, useAnalytics } from '../../analytics'
import {
  AI_CONTENT_ID_GENERATION_MESSAGE,
  MISSING_CONTENT_ID_MESSAGE,
} from '../../constants'
import {
  useAutoSave,
  useCloseFromHubtype,
  useIsFirstRender,
  useSaveBeforeUnload,
  useSaveChangesOnKeyPress,
} from '../../custom-hooks'
import { useBotConfig } from '../../custom-hooks/use-bot-config'
import { useFeedbackMessage } from '../../custom-hooks/use-feedback-message'
import { postCloseableStatusMessage } from '../../hubtype-events'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { setFBAppStoragePreviewState } from '../../test-webchat-app/utils'
import { PopupType } from '../../types'
import {
  FadeInOutContainer,
  HtBtnType,
  HtButton,
  HtIcon,
  HtTooltip,
  Icon,
  Size,
} from '../base'
import { HorizontalContainer } from '../base/containers'
import { TooltipPlacement } from '../base/ht-tooltip/ht-tooltip-styles'
import { ConnectionStatus } from './connection-status'
import { FlowActionPreviewButton } from './flow-action-preview-button'
import {
  FixedWidthContainer,
  SavedText,
  StyledWaringButton,
} from './header-styles'

export const ONLINE_TIMEOUT = 2000
export const OFFLINE_TIMEOUT = 10000

export interface FlowActionButtonsProps {
  isPublished: boolean
  onSave: (origin: SaveOrigin, isRestoring?: boolean) => Promise<boolean>
  onExit: (closeData?: string) => Promise<void>
}

export const FlowActionButtons = ({
  isPublished,
  onSave,
  onExit,
}: FlowActionButtonsProps): JSX.Element => {
  const analytics = useAnalytics()
  const { reportError, reportCustomFeedback } = useFeedbackMessage()
  const { state, setPopupContent, removeFeedbackMessages } =
    useFlowBuilderSelector(ctx => ctx)
  const isFirstRender = useIsFirstRender()

  const { isPreviewEnabled } = useBotConfig()

  const [hasFlowErrors, setHasFlowErrors] = useState(false)

  const [isSaved, _setIsSaved] = useState(true)
  const isSavedRef = useRef(isSaved)

  const setIsSaved = (savedStatus: boolean) => {
    isSavedRef.current = savedStatus
    _setIsSaved(savedStatus)
  }

  useEffect(() => {
    const hasErrors = state.nodes.some(
      node =>
        node.data.errors.showErrors &&
        (node.data.errors.fieldErrors.length ||
          node.data.errors.hasDuplicatedCode)
    )
    if (hasErrors && !isSaved) return
    setHasFlowErrors(hasErrors)
  }, [state.nodes, isSaved])

  useEffect(() => {
    const saved = state.isFlowSaved
    if (saved !== isSaved) {
      setIsSaved(saved)
      postCloseableStatusMessage(saved)
    }
    if (!isFirstRender && state.isFlowSaved) {
      const botId = state.organizationContents.conversationalApp.id
      setFBAppStoragePreviewState(botId, {
        hasFlowErrors: hasFlowErrors,
        current: state.currentLocale,
        locales: state.locales,
      })
    }
  }, [state.isFlowSaved])

  const save = async (origin: SaveOrigin) => {
    if (
      isSaved ||
      !!state.currentVersion ||
      state.isReadOnly ||
      state.isOffline
    )
      return
    await onSave(origin)
  }

  const publish = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    blurButton(evt)
    setPopupContent({ type: PopupType.PUBLISH })
    await analytics.trackEvent(TrackEventName.CLICK_PUBLISH_CTA)
  }

  const isPublishButtonDisabled = () => {
    return (
      hasFlowErrors ||
      (isPublished && state.isFlowSaved) ||
      !!state.currentVersion ||
      state.isReadOnly ||
      state.isOffline ||
      isFirstRender ||
      !state.isFlowSaved
    )
  }

  const blurButton = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation()
    evt.currentTarget.blur()
  }

  useSaveChangesOnKeyPress(isSaved, save)
  useAutoSave(state, save)
  useSaveBeforeUnload(isSaved, save)
  useCloseFromHubtype(isSaved, onExit)

  const showErrors = () => {
    removeFeedbackMessages()
    let showAiContentIdGenerationMessage
    const errorCountLocales = state.nodes.reduce(
      (acc, node) => {
        const { fieldErrors, localesWithErrors, hasDuplicatedCode } =
          node.data.errors
        fieldErrors.forEach(error => {
          reportError(error, { nodeId: node.id })
          if (error.includes(MISSING_CONTENT_ID_MESSAGE)) {
            showAiContentIdGenerationMessage = true
          }
        })
        localesWithErrors.forEach(locale => {
          acc[locale] = acc[locale] ? acc[locale] + 1 : 1
        })
        if (hasDuplicatedCode) {
          reportError(`Duplicated content id: ${node.data.code}`, {
            nodeId: node.id,
          })
        }
        return acc
      },
      {} as Record<string, number>
    )

    if (showAiContentIdGenerationMessage) {
      reportCustomFeedback(AI_CONTENT_ID_GENERATION_MESSAGE)
    }

    Object.entries(errorCountLocales).forEach(([locale, count]) => {
      if (locale !== state.currentLocale.code) {
        reportError(`${count} errors in language: ${locale}\n`, {
          localeCode: locale,
        })
      }
    })
  }

  return (
    <>
      <HorizontalContainer $size={Size.SMALL} $align='center'>
        <FixedWidthContainer>
          <ConnectionStatus />
          <FadeInOutContainer $isActive={isSaved && !state.isReadOnly}>
            <SavedText>Saved</SavedText>
          </FadeInOutContainer>
          {hasFlowErrors && (
            <HtTooltip tooltip='See errors' placement={TooltipPlacement.BOTTOM}>
              <StyledWaringButton>
                <HtButton
                  size={Size.TINY}
                  type={HtBtnType.TERTIARY}
                  onClick={showErrors}
                >
                  <HtIcon icon={Icon.EXCLAMATION_TRIANGLE} />
                </HtButton>
              </StyledWaringButton>
            </HtTooltip>
          )}
        </FixedWidthContainer>
        {isPreviewEnabled && (
          <FlowActionPreviewButton blurButton={blurButton} exit={onExit} />
        )}
        <HtTooltip
          tooltip='Fix errors to publish'
          placement={TooltipPlacement.BOTTOM}
          isDisabled={!hasFlowErrors}
        >
          <HtButton
            size={Size.TINY}
            disabled={isPublishButtonDisabled()}
            onClick={publish}
          >
            Publish
          </HtButton>
        </HtTooltip>
      </HorizontalContainer>
      <HtButton
        size={Size.TINY}
        type={HtBtnType.TERTIARY}
        onClick={evt => {
          blurButton(evt)
          onExit()
        }}
      >
        Exit
      </HtButton>
    </>
  )
}
