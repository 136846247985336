import { AnalyticsBrowser } from '@segment/analytics-next'
import { useEffect, useState } from 'react'

import { Feedback } from '../domain/feedback'
import { createRepository, Repository } from '../repository/repository-utils'
import { AnalyticsProvider, getAnalyticsWriteKey } from './analytics'
import { HtLoadingScreen } from './components/base'
import FlowBuilder from './flow-builder'
import { Container } from './flow-styles'
import {
  getFlowBuilderSettings,
  postFlowBuilderInitializedMessage,
} from './hubtype-events'
import { useFlowBuilder } from './reducer/hooks'
import { FlowBuilderProvider } from './reducer/provider'
import { LoadingMessage } from './types'

export const FlowBuilderApp = (): JSX.Element => {
  const store = useFlowBuilder()
  const [analytics] = useState(() =>
    AnalyticsBrowser.load({ writeKey: getAnalyticsWriteKey() })
  )
  const [repository, setRepository] = useState<Repository | undefined>(
    undefined
  )

  useEffect(() => {
    store.setLoadingMessage(LoadingMessage.LOADING_APP)
    const hasRepository = getRepositoryFromURL()
    if (!hasRepository) {
      postFlowBuilderInitializedMessage()
      window.addEventListener('message', getRepositoryFromSettings)
    }
  }, [])

  const getRepositoryFromURL = (): boolean => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    return getRepository(Object.fromEntries(urlSearchParams))
  }

  const getRepositoryFromSettings = (evt: MessageEvent) => {
    const data = getFlowBuilderSettings(evt)
    if (!data) return
    const hasRepository = getRepository(data)
    if (hasRepository) {
      window.removeEventListener('message', getRepositoryFromSettings)
    }
  }

  const getRepository = (data: any): boolean => {
    if (!data.authToken) return false
    store.setAuthToken(data.authToken)
    setRepository(createRepository(new Feedback()))
    return true
  }

  return (
    <Container height={'100vh'}>
      <AnalyticsProvider analytics={analytics}>
        {store.state.loadingMessage && (
          <HtLoadingScreen loadingMessage={store.state.loadingMessage} />
        )}
        {store.state.authToken && repository && (
          <FlowBuilderProvider store={store}>
            <FlowBuilder repository={repository} />
          </FlowBuilderProvider>
        )}
      </AnalyticsProvider>
    </Container>
  )
}
