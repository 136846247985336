import { useState } from 'react'

import { Locale } from '../../../../domain/models/locales/locale'
import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import {
  HtBtnType,
  HtButton,
  HtIcon,
  HtSelectDropdown,
  HtTooltip,
  Icon,
  Size,
} from '../../base'
import { headerDropdownProps } from '../header'
import { ComponentTitle, StyledFooter } from './locale-dropdown-styles'

interface LocaleDropdownProps {
  changeLocale: (locale: Locale) => void
}

export const LocaleDropdown = (props: LocaleDropdownProps): JSX.Element => {
  const analytics = useAnalytics()
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined)
  const stateLocales = useFlowBuilderSelector(ctx => ctx.state.locales)
  const isReadOnly = useFlowBuilderSelector(ctx => ctx.state.isReadOnly)
  const stateCurrentLocale = useFlowBuilderSelector(
    ctx => ctx.state.currentLocale
  )
  const toggleLocalesPanel = useFlowBuilderSelector(
    ctx => ctx.toggleLocalesPanel
  )

  const changeLocale = (localeId?: string) => {
    const selectedLocale = stateLocales.find(locale => locale.code === localeId)
    if (selectedLocale) props.changeLocale(selectedLocale)
  }

  const onOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen)
    if (!isOpen) return
    analytics.trackEvent(TrackEventName.LANGUAGES_OPEN, {
      number_of_languages: stateLocales.length,
      languages: stateLocales.map(locale => locale.getLocaleString()),
    })
  }

  const openLocalesPanel = () => {
    setIsOpen(false)
    analytics.trackEvent(TrackEventName.LANGUAGES_ADD_REMOVE_CTA)
    toggleLocalesPanel(true)
  }

  return (
    <HtSelectDropdown
      {...headerDropdownProps}
      data={stateLocales}
      isOpen={isOpen}
      label={stateCurrentLocale.getLocaleString()}
      onChange={locale => changeLocale(locale?.code)}
      onOpenChange={onOpenChange}
      selectedKey={stateCurrentLocale?.code}
      renderButtonAsItem={true}
      header={
        <ComponentTitle>
          <HtTooltip
            tooltip='Conversational apps can be available in multiple languages. Here you
        can manage translations but the conversation flow can’t be changed
        across different languages and every flow change you make will be
        applied to all languages.'
          >
            Content languages
            <HtIcon icon={Icon.CIRCLE_QUESTION} size={Size.TINY} />
          </HtTooltip>
        </ComponentTitle>
      }
      footer={
        isReadOnly ? undefined : (
          <StyledFooter>
            <HtButton
              size={Size.TINY}
              type={HtBtnType.TERTIARY}
              onClick={() => openLocalesPanel()}
            >
              Add or remove languages
            </HtButton>
          </StyledFooter>
        )
      }
    >
      {item => `${item.language} (${item.code})`}
    </HtSelectDropdown>
  )
}
