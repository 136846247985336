import { useEffect } from 'react'

import { getCloseFromHubtypeData } from '../hubtype-events'

export const useCloseFromHubtype = (
  isSaved: boolean,
  onExit: (closeData?: string) => Promise<void>
) => {
  useEffect(() => {
    const handleMessage = async (evt: MessageEvent) => {
      const closeData = getCloseFromHubtypeData(evt)
      if (closeData) await onExit(closeData)
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [isSaved])
}
