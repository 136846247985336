import { useEffect } from 'react'

import { SaveOrigin } from '../analytics'

export const useSaveBeforeUnload = (
  isSaved: boolean,
  save: (origin: SaveOrigin) => Promise<void>
) => {
  useEffect(() => {
    const handleBeforeUnload = async (evt: BeforeUnloadEvent) => {
      if (!isSaved) {
        evt.preventDefault()
        await save(SaveOrigin.ON_EXIT)
        evt.returnValue = true
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isSaved])
}
